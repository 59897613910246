<template>
  <vue-apex-charts
    :options="getChartOptions"
    :series="series"
    height="100%"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "BarChart",
  components: {VueApexCharts},
  props: {
    series: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      default: () => [
        '#F9844A', '#d7484b', '#4D908E', '#F9C74F', '#277DA1',
        '#F8961E', '#90BE6D', '#F3722C', '#43AA8B', '#577590',
      ]
    },
    /**
     * True = Horizontal
     * False = Vertical
     */
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getChartOptions() {
      let options = {
        chart: {
          type: 'bar'
        },
      };

      if (this.horizontal) {
        this.$set(options, 'plotOptions', {
          bar: {
            horizontal: true
          }
        })
      }

      if (this.getColors) {
        this.$set(options, 'colors', this.getColors);
      }

      return options;
    },

    getColors() {
      let colors = this.colors;
      colors.unshift(this.$luxcaddyConfig('home.charts.colors.primary'));

      return colors;
    }
  },
}
</script>

<style scoped>

</style>